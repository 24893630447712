// 设置sessionStorage
const setSession = (name, data) => {
  sessionStorage.setItem(name.toUpperCase(), JSON.stringify(data))
}
// 移除sessionStorage
const removeSession = name => {
  sessionStorage.removeItem(name.toUpperCase())
}

// 设置localStorage
const setLocal = (name, data) => {
  localStorage.setItem(name.toUpperCase(), JSON.stringify(data))
}

// 获取sessionStorage
const getSession = (name, data) => {
  let result = undefined
  const _name = name.toUpperCase()
  const _data = sessionStorage.getItem(_name)
  if (_data) {
    result = JSON.parse(_data)
  }

  return result
}

// 获取localStorage
const getLocal = (name, data) => {
  let result = undefined
  const _name = name.toUpperCase()
  const _data = localStorage.getItem(_name)
  if (_data) {
    result = JSON.parse(_data)
  }

  return result
}

// 移除sessionStorage
const removeLocal = name => {
  localStorage.removeItem(name.toUpperCase())
}

// 深拷贝（待优化）
const dClone = data => {
  return JSON.parse(JSON.stringify(data))
}
// 获取url参数
const getQueryVariable = variable => {
  const after = window.location.hash.split('?', 2)[1]
  let result = ''

  if (after) {
    const reg = new RegExp('(^|&)' + variable + '=([^&]*)(&|$)')
    const r = after.match(reg)
    if (r != null) {
      result = decodeURIComponent(r[2])
    } else {
      result = ''
    }
  }

  return result
}

// 输入框校验
const validator = {
  idCard: val => {
    const reg15 = /^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$/
    const reg18 = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
    return reg15.test(val) || reg18.test(val)
  },
  phone: val => {
    const reg = /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(17[0-9]{1})|(18[0-9]{1})|(19[0-9]{1})|(16[0-9]{1}))+\d{8})$/
    return val.length === 11 && reg.test(val)
  },
  email: val => {
    const reg = new RegExp(
      '^[a-z0-9A-Z]+[- | a-z0-9A-Z . _]+@([a-z0-9A-Z]+(-[a-z0-9A-Z]+)?\\.)+[a-z]{2,}$'
    )
    return reg.test(val)
  },
  postCode: val => {
    const reg = /^[0-9]{6}/
    return reg.test(val)
  }
}
const getUrlParam = function (name) {
  return (
    decodeURIComponent(
      (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
        location.href
      ) || [, ''])[1].replace(/\+/g, '%20')
    ) || null
  )
}

export {
  setSession,
  removeLocal,
  removeSession,
  getSession,
  dClone,
  setLocal,
  getLocal,
  getQueryVariable,
  getUrlParam,
  validator
}
