import axios from "axios"
import { getSession, removeLocal, removeSession } from "../util/util"
import { Toast } from "vant"

const { VUE_APP_BASE_URL } = process.env
// 创建 axios 实例
const service = axios.create({
  baseURL: VUE_APP_BASE_URL,
  // baseURL: "http://192.168.0.135:8080",
  timeout: 60000,
})

//  请求拦截器
service.interceptors.request.use(
  (config) => {
    config.headers["Content-Type"] = "application/x-www-form-urlencoded"
    let token = getSession("policyToolsToken") ? getSession("policyToolsToken") : null
    if (token) {
      config.headers["X-Api-Token"] = token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data
    if (res.status === 0) {
      return res
    } else if (res.status === 40301 || res.status === 40010) {
      Toast.fail({
        message: res.msg,
        duration: 2000,
      })
      // token过期就清除登录信息，路由会自动判断跳到登录页面
      removeSession("policyToolsInfo")
      removeSession("policyToolsToken")
      removeLocal("policyToolsOpenId")
      location.reload()
      return new Promise(() => { })
    } else {
      Toast.fail({
        message: res.msg,
        duration: 2000,
      })
      return Promise.reject(res.msg)
    }
  },
  (error) => {
    console.dir(error)
    Toast({
      className: "toastWidth",
      message: `状态码${error.response.status}，${error.response.statusText}`,
      duration: 2000,
    })
    return Promise.reject(error)
  }
)

export default service
