import Vue from 'vue'
import App from './App.vue'
import 'amfe-flexible'
import Vant from 'vant'
import router from './router'
import './assets/normallize.css'
import './assets/reset.css'
import './assets/global.less'
import 'vant/lib/index.css'

Vue.use(Vant)

Vue.config.productionTip = false
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
