import Vue from "vue"
import Router from "vue-router"
import request from "../api/request"
import { getQueryVariable, removeLocal, removeSession, getUrlParam, setLocal, setSession, getLocal, getSession } from "../util/util"
import { Toast } from "vant"
const { VUE_APP_APPID, VUE_APP_TITLE } = process.env;

// 解决报错Navigation cancelled from ...
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

Vue.use(Router)

const routes = [
  // 个人中心
  {
    path: "/",
    name: "Home",
    component: () => import("../views/user/Home.vue"),
    meta: {
      title: "个人中心",
      keepAlive: false,
    },
  },
  // 认证信息填写页
  {
    path: "/authentication",
    name: "Authentication",
    component: () => import("../views/user/Authentication.vue"),
    meta: {
      title: "填写信息",
      keepAlive: false,
    },
  },
  // 人脸验证
  {
    path: "/faceCheck",
    name: "FaceCheck",
    component: () => import("../views/user/FaceCheck.vue"),
    meta: {
      title: "人脸验证",
      keepAlive: false,
    },
  },
  // 我的订单
  {
    path: "/orderList",
    name: "OrderList",
    component: () => import("../views/order/OrderList.vue"),
    meta: {
      title: "我的订单",
      keepAlive: false,
    },
  },
  // 我的人核订单
  {
    path: "/muwOrderList",
    name: "MuwOrderList",
    component: () => import("../views/order/MuwOrderList.vue"),
    meta: {
      title: "我的人核订单",
      keepAlive: false,
    },
  },
  // 我的人核订单详情
  {
    path: "/muwOrderDetail",
    name: "MuwOrderDetail",
    component: () => import("../views/order/MuwOrderDetail.vue"),
    meta: {
      title: "人核订单详情",
      keepAlive: false,
    },
  },
  // 订单详情
  {
    path: "/orderDetail",
    name: "OrderDetail",
    component: () => import("../views/order/OrderDetail.vue"),
    meta: {
      title: "订单详情",
      keepAlive: false,
    },
  },
  // 理赔管理
  {
    path: "/claim",
    name: "Claim",
    component: () => import("../views/claims/Claim.vue"),
    meta: {
      title: "理赔管理",
      keepAlive: false,
    },
  },
  // 续保管理
  {
    path: "/renewalPolicy",
    name: "RenewalInsurance",
    component: () => import("../views/renewal/RenewalInsurance.vue"),
    meta: {
      title: "续保管理",
      keepAlive: false,
    },
  },
]

const router = new Router({
  routes,
})

router.beforeEach((to, from, next) => {
  document.title = VUE_APP_TITLE
  let toast
  let openId = getLocal("policyToolsOpenId")
  let token = getSession("policyToolsToken")
  if (!token || !openId) {
    console.log("getwxopenid open")
    toast = Toast.loading({
      overlay: true,
      forbidClick: true,
      message: "请稍后...",
      duration: 0,
    })
    getwxopenid().then((res) => {
      // alert("getwxopenid open")
      toast.clear()
      if (res.status != 0) {
        removeSession("policyToolsInfo")
        removeSession("policyToolsToken")
        removeLocal("policyToolsOpenId")
        location.reload()
      } else {
        if (to.path == "/") {
          next()
        } else {
          if (getSession("policyToolsInfo").certified != 1) {
            next("/")
          } else {
            next()
          }
        }
      }
    })
  } else {
    next()
  }
})

const getwxopenid = function () {
  return new Promise(function (resolve, reject) {
    let REDIRECT_URI
    // alert(location.protocol + "//" + location.host + location.pathname)
    if (getSession("firstLocalUrl")) {
      REDIRECT_URI = window.encodeURIComponent(getSession("firstLocalUrl"))
    } else {
      REDIRECT_URI = window.encodeURIComponent(location.href)
    }
    // const REDIRECT_URI = window.encodeURIComponent("http://192.168.0.140:8080/")
    const wechatUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${VUE_APP_APPID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect`
    let code = getQueryVariable("code") || getUrlParam("code") || null
    const wechatOpenid = (obj) => {
      return request({
        url: "/api/tools_policy/token",
        method: "GET",
        params: obj,
      })
    }

    if (!code) {
      if (!getSession("firstLocalUrl")) {
        setSession("firstLocalUrl", location.href)
      }
      window.location.href = wechatUrl
    } else {
      const sessionCode = getSession("policyToolsCode")
      if (code === sessionCode) {
        window.location.href = wechatUrl
        Toast({
          message: "code已被使用",
        })
      } else {
        setSession("policyToolsCode", code)
        wechatOpenid({
          code,
        }).then((res) => {
          if (res.status === 0) {
            const { openid, token } = res.data
            setLocal("policyToolsOpenId", openid)
            setSession("policyToolsInfo", res.data)
            setSession("policyToolsToken", token)

            resolve(res)
          } else {
            window.alert("微信身份识别失败 \n " + res.msg)
            // resolve(res)
          }
        })
      }
    }
  })
}
export default router
